type OwnProps<Wrapper extends React.ElementType> = {
  if?: boolean;
  with: Wrapper;
  children: React.ReactNode;
};

type Props<Wrapper extends React.ElementType> = OwnProps<Wrapper> &
  Omit<React.ComponentPropsWithoutRef<Wrapper>, keyof OwnProps<Wrapper>>;

/**
 * React component to conditionally wrap children
 * WARNING!: use carefully since it causes re-mount of component when condition changes
 * @example
 * pass react Element
 * ```
 * <Wrap if={clickable} with={AwesomeButton}>
 *   Hello
 * </Wrap>
 * ```
 * or use html tag names
 * ```
 * <Wrap if={clickable} with="button">
 *   Hello
 * </Wrap>
 * ```
 */
export const Wrap = <T extends React.ElementType>({
  if: condition,
  with: With,
  children,
  ...rest
}: Props<T>) => {
  return condition ? (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <With {...(rest as any)}>{children}</With>
  ) : (
    children
  );
};
